import React from "react";
import Head from "../../components/head.js";
import { OutboundLink } from "gatsby-plugin-google-analytics";
// import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Layout from "../../components/layout.js";

const Page = () => {
  return (
    <Layout>
      <Head title="Dispel [Zine]" />
      <h3>Dispel [Zine]</h3>
      {/* <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        Pahrump, NV
      </h4> */}
      <p>
        <OutboundLink href="https://ello.co/dispel">Ello</OutboundLink>
      </p>

      <p>
        Micropublication / Psychogeography, photography, and more / e-zine by
        the Ugly Collective.
      </p>

      <img
        style={{ marginBottom: 5 }}
        src="https://assets0.ello.co/uploads/asset/attachment/15985969/ello-optimized-42e6dd66.jpg"
        alt="Dispel [Zine] photography"
      />
      <p>
        <b>Walls</b> / Elkstone Road, London, England.
      </p>
      <img
        style={{ marginBottom: 5 }}
        src="https://assets2.ello.co/uploads/asset/attachment/15985968/ello-optimized-ce921153.jpg"
        alt="Dispel [Zine] photography"
      />
      <p>
        <b>Walls</b> / West 30th Street, New York City, New York.
      </p>
      <img
        style={{ marginBottom: 5 }}
        src="https://assets0.ello.co/uploads/asset/attachment/15984775/ello-optimized-b8ad5a78.jpg"
        alt="Dispel [Zine] photography"
      />
      <p>
        <b>Walls</b> / Witherby Street, Oceanside, California.
      </p>
      <img
        style={{ marginBottom: 5 }}
        src="https://assets1.ello.co/uploads/asset/attachment/15984773/ello-optimized-e499f3a5.jpg"
        alt="Dispel [Zine] photography"
      />
      <p>
        <b>Walls</b> / South Fourth Street, O'Neill, Nebraska.
      </p>
      <img
        style={{ marginBottom: 5 }}
        src="https://assets1.ello.co/uploads/asset/attachment/15982116/ello-optimized-5d607358.jpg"
        alt="Dispel [Zine] photography"
      />
      <p>
        <b>Walls</b> / South Bundy Drive, Los Angeles, California.
      </p>
    </Layout>
  );
};

export default Page;
